
//jQuery spotlight plugin by A.R.Shakhmukhametov and A.V.Aksyutenko
$.fn.spotlight=function(opts){
    $(this).load(function(){

        var pos=$(this).offset();
        var vouale;
        var spotlight;
        var spotimage;
        var spotmask;

        var a = $(this).attr('id')
        var a = $(this).attr('class')
        console.log(a);
        b = a + "_blc closed";
        vouale=document.createElement('div');
        vouale.setAttribute( "class", b );
        $(vouale).css('position','absolute');
        $(vouale).css('top',pos.top);
        $(vouale).css('right',0);
        $(vouale).css('cursor','none');
        $(vouale).css('overflow','hidden');
        $(vouale).width(1138);
        $(vouale).height(435);
        $(vouale).css('background-image','url(../images/all_close.png)');
        document.body.appendChild(vouale);
        spotlight=document.createElement("div");
        $(spotlight).width(275);
        $(spotlight).height(275);
        $(spotlight).css('border-radius','50%');
        $(spotlight).css('overflow','hidden');
        $(spotlight).css('display','none');
        $(spotlight).css('position','absolute');
        $(spotlight).css('left',pos.left);
        $(spotlight).css('top',pos.top);
        $(spotlight).css('z-index',500);
        spotimage=document.createElement('img');
        spotimage.setAttribute( "id", "main_img" );
        $(spotimage).attr('src',$(this).attr('src'));
        $(spotimage).css('height',$(this).height());
        $(spotimage).css('width',$(this).width());
        spotmask=document.createElement('img');
        $(spotmask).attr('src','../images/spotlight.png');
        $(spotmask).css('position','absolute');
        $(spotmask).css('left',pos.left);
        $(spotmask).css('top',pos.top);
        $(spotmask).css('z-index',550);
        spotlight.appendChild(spotimage);
        vouale.appendChild(spotlight);
        vouale.appendChild(spotmask);
        $('body').mousemove( function(e){
            var x=e.pageX-(pos.left)-75;
            var y=e.pageY-(pos.top)-75;
            $(spotlight).css('display','');
            $(spotlight).css('left',x);
            $(spotlight).css('top',y);
            $(spotmask).css('left',x);
            $(spotmask).css('top',y);
            $(spotimage).css('marginLeft',-x);
            $(spotimage).css('marginTop',-y);
        });




    }).each(function(){
        if(this.complete || (navigator.userAgent.toUpperCase().indexOf('MSIE') && parseInt(navigator.userAgent.toUpperCase().indexOf('MSIE')) == 6))
            $(this).trigger("load");
    });
    ;}



/* change opacity */

$('#choose_fiz').find('li').hover(function () {
    $('.closed').css('opacity','0.6');
}, function () {
    $('.closed').css('opacity','1');
});

$('#li4_mebel').hover(function () {

   $('#main_img').attr('src','../images/dop/li4_moto.png');
   $('.img_cont').attr('src','../images/dop/li4_moto.png');
});

